import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authApi, getUserData, getUserNotifications } from "services/auth";
import {
    IAuthState,
    IGetNotificationsResponse,
    IGetUserDataResponse,
    ISetNotificationSeenResponse,
    IUpdateUserPaymentDetailsResponse,
} from "types/auth";
import { removeAccessToken, setAccessToken } from "utils/auth";

const initialState: IAuthState = {
    user: null,
    authData: undefined,
    isLoading: false,
    userNotifications: {
        pagination: {},
        notifications: [],
    },
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signOut: () => initialState,
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                authApi.endpoints.getUserData.matchPending,
                state => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                authApi.endpoints.getUserData.matchFulfilled,
                (state, action: PayloadAction<IGetUserDataResponse>) => {
                    const { _id, ...rest } = action.payload.user;
                    state.authData = { _id };
                    state.user = rest;
                    state.isLoading = false;
                }
            )
            .addMatcher(
                authApi.endpoints.getUserData.matchRejected,
                state => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                authApi.endpoints.getUserNotifications.matchFulfilled,
                (state, action: PayloadAction<IGetNotificationsResponse>) => {
                    state.userNotifications = action.payload.data;
                }
            )
            .addMatcher(
                authApi.endpoints.setNotificationSeen.matchFulfilled,
                (state, action: PayloadAction<ISetNotificationSeenResponse>) => {
                    const updatedNotification = action.payload.notification;
                    const index = state.userNotifications.notifications.findIndex(n => n._id === updatedNotification._id);

                    if (index !== -1) {
                        state.userNotifications.notifications[index] = updatedNotification;
                    }
                }
            )
            .addMatcher(
                authApi.endpoints.updateCurrentUser.matchFulfilled,
                (state, action: PayloadAction<IGetUserDataResponse>) => {
                    state.user = action.payload.user;
                }
            )
            .addMatcher(
                authApi.endpoints.updateUserPaymentDetails.matchFulfilled,
                (state, action: PayloadAction<IUpdateUserPaymentDetailsResponse>) => {
                    state.user = action.payload.data.client;
                }
            )
            .addMatcher(
                authApi.endpoints.resetPassword.matchFulfilled,
                (_state, _action) => {}
            )
            .addMatcher(
                authApi.endpoints.forgotPassword.matchFulfilled,
                (_state, _action) => {}
            );
    },
});

export const getCurrentUser = createAsyncThunk(
    "auth/getCurrentUser",
    async (_, { dispatch }) => {
        return dispatch(getUserData.initiate(undefined, {
            subscribe: false,
            forceRefetch: false,
        }));
    }
);

export const getNotifications = createAsyncThunk(
    "auth/getNotifications",
    async (_, { dispatch }) => {
        return dispatch(getUserNotifications.initiate(undefined, {
            subscribe: false,
            forceRefetch: false,
        }));
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async (token: string, { dispatch }) => {
        setAccessToken(token);
        dispatch(getCurrentUser());
    }
);

export const logout = createAsyncThunk("auth/logout", async (_, { dispatch }) => {
    removeAccessToken();
    dispatch(authSlice.actions.signOut());
});

export default authSlice.reducer;
