import {
    IDeleteDocumentReceiptRequestBody,
    IEmail,
    IEmailAvailabilityQuery,
    IGetUserDataResponse,
    IGoogleSignInRequestBody,
    IResetPassword,
    ISignInRequestBody,
    ISignUpRequestBody,
    IUpdateUserAdditionalInfoRequestBody,
    IConfirmDocPaymentPlanRequestBody,
    IUpdateUserDocumentRequestBody,
    IUpdateUserRequestBody,
    IGetNotificationsResponse,
    IUpdateUserPaymentDetailsRequestBody,
    IResetPasswordAvailabilityQuery,
    IAvailabilityResponse,
    IUpdateUserAgentLinkInfoRequestBody,
    IReAuthorizeDocumentRequestBody,
} from "types/auth";

import { api } from "./connection/api";

const ENDPOINT_PREFIX = "clients/auth";

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        signIn: build.mutation({
            query(data: ISignInRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-in`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        signInGoogle: build.mutation({
            query(data: IGoogleSignInRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-in-google`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        signUp: build.mutation({
            query(data: ISignUpRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-up`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        getUserData: build.query<IGetUserDataResponse, void>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/`,
            }),
            providesTags: ["Auth"],
        }),
        getUserNotifications: build.query<IGetNotificationsResponse, void>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/notifications`,
            }),
            providesTags: ["Notifications"],
        }),
        setNotificationSeen: build.mutation({
            query(notificationId: string) {
                return {
                    url: `${ENDPOINT_PREFIX}/notifications/${notificationId}/seen`,
                    method: "PATCH",
                };
            },
            invalidatesTags: ["Notifications"],
        }),
        updateCurrentUser: build.mutation({
            query(data: IUpdateUserRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: [],
        }),
        updateUserDocument: build.mutation({
            query({ documentId, ...data }: IUpdateUserDocumentRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        updateUserAdditionalInfo: build.mutation({
            query(data: IUpdateUserAdditionalInfoRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/additional-info`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: [],
        }),
        updateUserPaymentDetails: build.mutation({
            query(data: IUpdateUserPaymentDetailsRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/payment-details`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: [],
        }),
        updateUserAgentLinkInfo: build.mutation({
            query({ clientId, ...data }: IUpdateUserAgentLinkInfoRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/agent-link?clientId=${clientId}`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: [],
        }),
        confirmDocPaymentPlan: build.mutation({
            query({ documentId, ...data }: IConfirmDocPaymentPlanRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/confirm`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: [],
        }),
        completeDocument: build.mutation({
            query({ documentId, _clientId }) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/completeDocument`,
                    method: "PUT",
                };
            },
            invalidatesTags: [],
        }),
        getDocuments: build.mutation({
            query() {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/`,
                    method: "GET",
                };
            },
            invalidatesTags: [],
        }),
        deleteDocumentReceipt: build.mutation({
            query({ documentId, ...data }: IDeleteDocumentReceiptRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/receipts`,
                    method: "DELETE",
                    body: data,
                };
            },
            invalidatesTags: [],
        }),
        checkEmailAvailability: build.query<
            IAvailabilityResponse,
            IEmailAvailabilityQuery
        >({
            query: (data: IEmailAvailabilityQuery) => ({
                url: `${ENDPOINT_PREFIX}/availability?email=${data.email}&userId=${data.id}`,
            }),
            providesTags: [],
        }),
        checkResetPasswordAvailability: build.mutation({
            query(data: IResetPasswordAvailabilityQuery) {
                return {
                    url: `${ENDPOINT_PREFIX}/reset-availability?email=${data.email}&nin=${data.nin}`,
                    method: "GET",
                };
            },
        }),
        forgotPassword: build.mutation({
            query(data: IEmail) {
                return {
                    url: `${ENDPOINT_PREFIX}/forgot-password`,
                    method: "PUT",
                    body: data,
                };
            },
        }),
        resetPassword: build.mutation({
            query(data: IResetPassword) {
                return {
                    url: `${ENDPOINT_PREFIX}/reset-password`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        signAgreement: build.mutation({
            query(data: FormData) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-agreement`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        deleteDocumentFile: build.mutation({
            query(data) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${data.documentId}/files`,
                    method: "DELETE",
                    body: data.data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        reAuthorizeDocument: build.mutation({
            query({ documentId, ...data }: IReAuthorizeDocumentRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/re-authorize`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        payMinimumFee: build.mutation({
            query({ documentId, ...data }: IReAuthorizeDocumentRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/documents/${documentId}/pay-minimum-fee`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
    }),
});

export const {
    useSignInMutation,
    useSignInGoogleMutation,
    useSignUpMutation,
    useUpdateCurrentUserMutation,
    useSetNotificationSeenMutation,
    useUpdateUserDocumentMutation,
    useUpdateUserAdditionalInfoMutation,
    useUpdateUserPaymentDetailsMutation,
    useConfirmDocPaymentPlanMutation,
    useCompleteDocumentMutation,
    useGetDocumentsMutation,
    useDeleteDocumentReceiptMutation,
    useDeleteDocumentFileMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useSignAgreementMutation,
    useCheckResetPasswordAvailabilityMutation,
    useUpdateUserAgentLinkInfoMutation,
    useReAuthorizeDocumentMutation,
    usePayMinimumFeeMutation,
    endpoints: { getUserData, checkEmailAvailability, getUserNotifications },
} = authApi;
