import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

import { logError } from "./sentry";

const environment = process.env.REACT_APP_ENV;

export const NewRelic = {
    init() {
        if (environment === "production") {
            const options = {
                init: {
                    distributed_tracing: { enabled: true },
                    privacy: { cookies_enabled: true },
                    ajax: { deny_list: ["bam.eu01.nr-data.net"] },
                },
                info: {
                    beacon: "bam.eu01.nr-data.net",
                    errorBeacon: "bam.eu01.nr-data.net",
                    licenseKey: "NRBR-c0b67e40444ade0fb25",
                    applicationID: "538646229",
                    sa: 1,
                },
                loader_config: {
                    accountID: "4818462",
                    trustKey: "4818462",
                    agentID: "538646229",
                    licenseKey: "NRBR-c0b67e40444ade0fb25",
                    applicationID: "538646229",
                },
            };

            new BrowserAgent(options);
        }
    },
    createCustomEvent(actionName: string, additionalData: object) {
        try {
            if (environment === "production") {
                const { newrelic } = window as any;
                if (newrelic) {
                    newrelic.addPageAction(actionName, { ...additionalData });
                } else {
                    throw new Error("New Relic doesn't exists");
                }
            }
        } catch (e) {
            const contextInfo = {
                actionName,
                error: e,
            };
            logError(e, contextInfo);
        }
    },
};
