import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountDetails } from "types/bankAccounts";
import { PaymentDetails } from "types/paymentDetails";

interface PaymentState {
    banks: PaymentDetails[];
    accounts: AccountDetails[]

}

const initialState: PaymentState = {
    banks: [],
    accounts: [],
};

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setBanks(state, action: PayloadAction<PaymentDetails[]>) {
            state.banks = action.payload;
        },
        setAccount(state, action: PayloadAction<AccountDetails[]>) {
            state.accounts = action.payload;
        },
    },
});

export const { setBanks, setAccount } = paymentSlice.actions;

export default paymentSlice.reducer;
