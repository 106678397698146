import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TaxProfile } from "types/client";

interface TaxProfileState {
    currentQuestionIndex: number;
    isEditingProfile: boolean;
    currentProfile: TaxProfile;
}

const initialState: TaxProfileState = {
    currentQuestionIndex: 0,
    isEditingProfile: false,
    currentProfile: {
        _id: "",
        parentType: "Self Employment",
        type: "",
        updatedAt: "",
        name: "",
        bankAccounts: [],
        client: "",
        createdAt: "",
        documents: [],
        revenue: {
            reviewableTransactionsTotal: 0,
            swipedApprovedTransactionsTotal: 0,
            confirmedTotalGross: 0,
            taxAlreadyPaidOnRevenue: 0,
        },
        expenses: {
            reviewableTransactionsTotal: 0,
            swipedApprovedTransactionsTotal: 0,
            confirmedTotal: 0,
            category: {},
        },
    },
};

const taxProfileSlice = createSlice({
    name: "taxProfile",
    initialState,
    reducers: {
        setCurrentQuestionIndex(state, action: PayloadAction<number>) {
            state.currentQuestionIndex = action.payload;
        },
        setIsEditingProfile(state, action: PayloadAction<boolean>) {
            state.isEditingProfile = action.payload;
        },
        setCurrentProfile(state, action: PayloadAction<TaxProfile>) {
            state.currentProfile = JSON.parse(JSON.stringify(action.payload));
        },
    },
});

export const {
    setCurrentQuestionIndex,
    setIsEditingProfile,
    setCurrentProfile,
} = taxProfileSlice.actions;

export default taxProfileSlice.reducer;
