import {
    IAddClientDocumentRequestBody,
    IEmailAvailabilityQuery,
    IEmailAvailabilityResponse,
    IIdAvailabilityQuery,
    INinAvailabilityQuery,
    INinAvailabilityResponse,
    GetAllClientTaxProfilesResponse,
    ICreateClientTaxProfileBody,
    IGetClientTaxProfilesBody,
    IUpdateClientTaxProfileBody,
    IGetClientTaxProfileBody,
    GetClientTaxProfileResponse,
    IDeleteClientTaxProfileBody,
} from "types/client";

import { api } from "./connection/api";

const ENDPOINT_PREFIX = "clients";

export const clientApi = api.injectEndpoints({
    endpoints: build => ({
        create: build.mutation({
            query(data: FormData) {
                return {
                    url: `${ENDPOINT_PREFIX}/`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Client"],
        }),
        getClientDocumentById: build.mutation({
            query({ clientId, documentId }) {
                return {
                    url: `${ENDPOINT_PREFIX}/${clientId}/documents/${documentId}`,
                    method: "GET",
                };
            },
        }),
        addClientDocument: build.mutation({
            query(data: IAddClientDocumentRequestBody) {
                const { clientId, ...body } = data;
                return {
                    url: `${ENDPOINT_PREFIX}/${clientId}/documents`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["Client", "Auth"],
        }),

        createClientTaxProfile: build.mutation<any, ICreateClientTaxProfileBody>({
            query: ({ ...data }: ICreateClientTaxProfileBody) => ({
                url: `${ENDPOINT_PREFIX}/${data.client}/taxProfiles`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["TaxProfiles"],
        }),

        getAllClientTaxProfiles: build.query<GetAllClientTaxProfilesResponse, IGetClientTaxProfilesBody>({
            query({ client, parentType }) {
                return {
                    url: `${ENDPOINT_PREFIX}/${client}/taxProfiles?parentType=${parentType}`,
                    method: "GET",
                };
            },
        }),

        getClientTaxProfile: build.query<GetClientTaxProfileResponse, IGetClientTaxProfileBody>({
            query({ client, taxProfileId }) {
                return {
                    url: `${ENDPOINT_PREFIX}/${client}/taxProfiles/${taxProfileId}`,
                    method: "GET",
                };
            },
        }),

        updateClientTaxProfile: build.mutation<any, IUpdateClientTaxProfileBody>({
            query: ({ taxProfileId, ...data }: IUpdateClientTaxProfileBody) => ({
                url: `${ENDPOINT_PREFIX}/${data.client}/taxProfiles/${taxProfileId}`,
                method: "PATCH",
                body: data,
            }),
            invalidatesTags: ["TaxProfiles"],
        }),

        deleteClientTaxProfile: build.mutation<any, IDeleteClientTaxProfileBody>({
            query: ({ taxProfileId, client }: IDeleteClientTaxProfileBody) => ({
                url: `${ENDPOINT_PREFIX}/${client}/taxProfiles/${taxProfileId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["TaxProfiles"],
        }),

        checkNinAvailability: build.query<
            INinAvailabilityResponse,
            INinAvailabilityQuery
        >({
            query: (data: INinAvailabilityQuery) => ({
                url: `${ENDPOINT_PREFIX}/availability?nin=${data.nin}`,
            }),
            providesTags: ["Client"],
        }),

        checkClientEmailAvailability: build.query<
            IEmailAvailabilityResponse,
            IEmailAvailabilityQuery
        >({
            query: (data: IEmailAvailabilityQuery) => ({
                url: `${ENDPOINT_PREFIX}/availability?email=${data.email}`,
            }),
            // providesTags: ["Client"],
        }),

        checkClientIdAvailability: build.query<
            IEmailAvailabilityResponse,
            IIdAvailabilityQuery
        >({
            query: (data: IIdAvailabilityQuery) => ({
                url: `${ENDPOINT_PREFIX}/availability?id=${data.id}`,
            }),
            // providesTags: ["Client"],
        }),

        unsubscribe: build.mutation({
            query(data: {email: string, subject?: string}) {
                return {
                    url: `${ENDPOINT_PREFIX}/unsubscribe`,
                    method: "POST",
                    body: data,
                };
            },
        }),

        resubscribe: build.mutation({
            query(data: {email: string}) {
                return {
                    url: `${ENDPOINT_PREFIX}/resubscribe`,
                    method: "POST",
                    body: data,
                };
            },
        }),

    }),
});

export const {
    useCreateMutation,
    useAddClientDocumentMutation,
    useGetClientDocumentByIdMutation,
    useCreateClientTaxProfileMutation,
    useGetAllClientTaxProfilesQuery,
    useGetClientTaxProfileQuery,
    useUpdateClientTaxProfileMutation,
    useDeleteClientTaxProfileMutation,
    useUnsubscribeMutation,
    useResubscribeMutation,
    endpoints: {
        checkNinAvailability,
        checkClientEmailAvailability,
        checkClientIdAvailability,
    },
} = clientApi;
