import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ConnectBank, ApiRegionResponse, InstitutionConnection } from "../../types/connectBank";

interface BankAccountState {
  countryRegion: ConnectBank[];
  countryBank: ApiRegionResponse | null;
  bankConnection: InstitutionConnection | null;
  openBankingError: boolean
}

const initialState: BankAccountState = {
    countryRegion: [],
    countryBank: null,
    bankConnection: null,
    openBankingError: false,
};
const bankSlice = createSlice({
    name: "bank",
    initialState,
    reducers: {
        setCountryRegions(state, action: PayloadAction<ConnectBank[]>) {
            state.countryRegion = action.payload;
        },
        setCountryBanks(state, action: PayloadAction<ApiRegionResponse | null>) {
            state.countryBank = action.payload;
        },
        setBankConnection(state, action: PayloadAction<InstitutionConnection | null>) {
            state.bankConnection = action.payload;
        },
        setOpenBankingError(state, action: PayloadAction<boolean>) {
            state.openBankingError = action.payload;
        },

    },
});

export const { setCountryRegions, setCountryBanks, setBankConnection, setOpenBankingError } = bankSlice.actions;

export default bankSlice.reducer;
