import * as Sentry from "@sentry/react";

export const logError = (error: any, context?: Record<string, any>) => {
    Sentry.captureException(error, {
        extra: {
            ...context,
            timestamp: new Date().toISOString(), // Include a timestamp
        },
        tags: {
            // You can add tags to categorize the error
            type: "error",
        },
    });
};

export const logMessage = (message: string) => {
    Sentry.captureMessage(message);
};
