import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FormDataState = {
    [key: string]: any;
};

const initialState: FormDataState = {};

export const formDataSlice = createSlice({
    name: "formData",
    initialState,
    reducers: {
        updateFormData: (state, action: PayloadAction<FormDataState>) => {
            return { ...state, ...action.payload };
        },
        clearFormData: () => initialState,
    },
});

export const { updateFormData, clearFormData } = formDataSlice.actions;

export default formDataSlice.reducer;
