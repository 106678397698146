/* eslint-disable import/order*/
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { logout } from "../../redux/reducers/auth";
import { getAccessToken } from "../../utils/auth";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: headers => {
        const token = getAccessToken();

        if (token) {
            headers.set("Authorization", "Bearer " + token);
        }

        return headers;
    },
});

const baseQueryWithIntercept: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        const data = result.error.data as unknown as any;
        if (
            (result.error.status === 401 && data?.message !== "Error: Unauthorized. Unable to authenticate the client") ||
            (result.error.status === 404 && data?.message === "Client not found, Logout")
        ) {
            api.dispatch(logout());
        }
    // window.location.href = "/auth";
    }

    return result;
};

export const api = createApi({
    baseQuery: baseQueryWithIntercept,
    keepUnusedDataFor: 10,
    tagTypes: [
        "Auth",
        "Client",
        "Questionnaire",
        "Files",
        "Notifications",
        "Years",
        "Reviews",
        "Idpal",
        "PotentialClients",
        "IncomeExpenseReports",
        "CategoryTransactionsGroups",
        "TaxProfiles",
    ],
    endpoints: () => ({}),
});
